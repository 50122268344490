@import '~antd/dist/antd.css';

.page-common-layout {
    background: white;
    min-width: 1250px;
    min-height: 570px;
    max-width: 1750px;
    height: 88vh;
    margin: auto;
}

.page-common-layout-header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    max-width: 1750px;
    min-width: 1250px;
    background: #3180e0;
    height: 6vh;
    margin: auto;
}

.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94%;
    height: 80%;
    background: transparent;
}

.header-func-outer {
    width: 26%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    font-size: 14px;
}

.page-footer {
    color: white;
}

.page-common-layout-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    height: 6vh;
    max-width: 1750px;
    min-width: 1250px;
    background: #3180e0;
    margin: auto;
}

.page-content-outer {
    /*display: flex;*/
    /*align-items: center;*/
    height: 90%;
    width: 94%;
    margin: auto;
    background: #ffffff;
}

.common-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98%;
    width: 100%;
    color: #4c4c4c;
    background: rgba(224, 235, 243, 0.7);
}

.core-key-content-outer {
    height: 84%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.core-key-content {
    height: 80%;
    width: 90%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.payment-info {
    width: 100%;
    height: 84%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-info-content {
    background: #ffffff;
    width: 90%;
    height: 90%;
}

.qr-pay-layer {
    height: 70%;
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qr-pic-level {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}

.qr-pic-level-img {
    height: 80%;
    max-width: 100%;
    object-fit: cover;
}

.qr-button {
    width: 120px;
    height: 16%;
    min-height: 26px;
    border: 0;
    background: #3180e0;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.qr-button-pic {
    width: 120px;
    height: 16%;
    min-height: 26px;
    border: 0;
    background: #3180e0;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.link-pay-layer {
    height: 30%;
    width: 80%;
    background: #ffffff;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.pay-link-line {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.pay-link-btn {
    width: 22%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.core-key-content-item {
    height: 24%;
    width: 60%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.host-key-content-item {
    height: 24%;
    width: 80%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.core-key-content-item-des {
    width: 60%;
    height: 100%;
}

.core-key-content-item-btn {
    width: 40%;
    height: 100%;
    text-align: right;
}

.core-layout {
    width: 96%;
    height: 96%;
    background: #b2d7ef;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
}

.media-layout {
    width: 96%;
    height: 100%;
    /*background: #b2d7ef;*/
}

.media-top-layout {
    display: flex;
    align-items: center;
    height: 8%;
    width: 100%;
    background: rgba(224, 235, 243, 0.7);
}

.media-top-layout-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    width: 100px;
    height: 30px;
    border: 0;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(114, 114, 114, 0.6);
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 4px;
    color: #214e76;
}

.media-top-layout-select-ele {
    font-size: 14px;
    letter-spacing: 4px;
    color: #214e76;
}

.media-center-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1px 0;
    height: 90%;
    width: 100%;
    border-left: 1px solid rgba(138, 138, 138, 0.8);
    background: rgba(224, 235, 243, 0.7);
    overflow-y: scroll;
}

.media-center-layout-ele {
    text-align: center;
    margin-left : -1px;
    margin-top : -1px;
    width: calc(100% / 3);
    height: 400px;
    background: white;
    border: 1px solid rgba(138, 138, 138, 0.8);
}

.media-center-layout-ele-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12%;
    border-bottom: 1px dashed gray;
    background-color: #3180e0;
    font-size: 16px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
}

.media-center-layout-ele-img {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 73%;
    overflow: hidden;
}

.img-size {
    width: 100%;
    height: 100%;
    /*object-fit: cover;*/
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.media-center-layout-ele-content {
    text-align: left;
    margin: 0 auto;
    padding: 5% 0;
    width: 80%;
    height: 70%;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 2px;
    word-break: break-all;
}

.media-center-layout-ele-fun {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
}

.media-center-layout-ele-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    width: 100px;
    height: 60%;
    border: 1px solid #3180e0;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 4px;
    color: #3180e0;
    cursor: pointer;
}

.input-file {
    display: none;
}

.media-button-layout {
    height: 2%;
    width: 100%;
    background: rgba(224, 235, 243, 0.7);
}

.core-side-layout {
    width: 48%;
    height: 96%;
    background: white;
    border-radius: 5px;
}

.base-info-show {
    height: 84%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.base-info-show-content {
    height: 80%;
    width: 86%;
    background: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.base-info-show-item {
    width: 60%;
    height: 46%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.base-info-show-item-des {
    font-size: 14px;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
}

.base-info-show-item-edit {
    text-align: center;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.award-info-level {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.award-info-content {
    width: 98%;
    height: 70%;
    background: #ffffff;
}

.award-info-bottom {
    height: 20%;
    background: #ffffff;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.award-info-input-bottom {
    height: 20%;
    background: #ffffff;
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.award-info-btn {
    border: 0;
    background: #3180e0;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    height: 26px;
    width: 120px;
}

.award-info-out {
    height: 84%;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.core-edit-button {
    border: 0;
    background: #3180e0;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    height: 30%;
    min-height: 26px;
    width: 80px;
}

.core-left-top {
    width: 100%;
    height: 50%;
    background: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.core-menu-button {
    width: 120px;
    min-height: 24px;
    height: 60%;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    color: #1b5fb3;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
}

.core-base-menus {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.core-part-title-top {
    width: 100%;
    height: 16%;
    background: #3180e0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    color: white;
}

.core-part-title-down {
    width: 100%;
    height: 16%;
    background: #3180e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    color: white;
}

.core-pay-title {
    /*width: ;*/
}

.core-pay-title-section {
    width: 24%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.core-pay-title-section-item {
    cursor: pointer;
}

.core-left-down {
    width: 100%;
    height: 50%;
    background: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.core-right-top {
    width: 100%;
    height: 50%;
    background: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.core-right-down {
    width: 100%;
    height: 50%;
    background: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.mobile-order-page-content {
    position: absolute;
    width: 100%;
    min-height: calc(100% - 150px);
    background: white;
}

.await-order-table {
    height: 96%;
    width: 96%;
    background: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.order-table-titles {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 7%;
    background: #c4e3ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-order-table-titles {
    height: 100px;
    width: 100%;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #36506f;
    letter-spacing: 2px;
    font-weight: bold;
}

.mobile-order-table-partition {
    height: 120px;
    width: 100%;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    color: #404040;
    letter-spacing: 2px;
    font-weight: bold;
}

.history-table-content-layout {
    height: 93%;
    overflow-y: scroll;
}

.await-order-table-data {
    background: #c0dcea;
    height: 92%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow-y: scroll;
}

.table-title-line {
    font-weight: bold;
    width: 98%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.table-data-line {
    margin: 0 auto 6px auto;
    width: 98%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 6%;
    min-height: 40px;
}

.mobile-collapse {
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-user-accept-button {
    border: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    height: 80%;
    border-radius: 10px;
    background: #2e78d0;
    color: white;
}

.mobile-order-detail {
    margin: auto;
    width: 90%;
    height: 400px;
    background: #ffffff;
    font-size: 30px;
}

.mobile-audit-button {
    width: 160px;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    color: white;
    height: 70px;
}

.mobile-table-data-line {
    margin: 0 auto 0 auto;
    width: 94%;
    height: 200px;
    font-size: 30px;
    background: #e1f1ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.mobile-data-line-op-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8%;
    width: 100px;
    height: 70px;
    border: 0;
    background: #2e78d0;
    color: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    border-radius: 4px;
}

.common-line-item {
    width: 13.5%;
    text-align: center;
    overflow: hidden;
}

.check-line-info {
    cursor: pointer;
    color: rgb(11,85,175);
    font-weight: bold;
}

.check-UID-info {
    margin: auto;
    width: 50%;
    height: 80px;
    cursor: pointer;
    color: rgb(11,85,175);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.show-info-win {
    font-size: 14px;
}

.small-line-item {
    width: 5.5%;
    text-align: center;
    overflow: hidden;
}

.small-line-item-switch {
    width: 5.5%;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    background: #f6f6f6;
    border-radius: 3px;
    color: #214e76;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.audit-button {
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.audit-button:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #3180e0;
    color: white;
    cursor: pointer;
}

.empty-data {
    height: 92%;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-empty-data {
    height: 500px;
    background: #e3f7ff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}

.empty-data-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26%;
    width: 30%;
    background: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
    border: 2px dashed #6b9ed4;
    color: #315799;
    border-radius: 10px;
}

.loading-data-notice {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26%;
    width: 30%;
    background: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
    color: #315799;
}

.audit-order-win {
    position: absolute;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    height: 300px;
    width: 380px;
}

.order-win-line {
    width: 80%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.order-pass {
    background: #cbffcb;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    width: 22%;
    height: 42%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.order-reject {
    background: #ffe7e1;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    width: 22%;
    height: 42%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.audit-order-win-title {
    width: 100%;
    height: 14%;
    background: rgb(37,119,220);
    display: flex;
    align-items: center;
    color: white;
    border-radius: 5px 5px 0 0;
}

.mobile-audit-order-win-title {
    width: 100%;
    height: 100px;
    background: rgb(101, 101, 101);
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 30px;
}

.accepts-win-title {
    width: 100%;
    height: 10%;
    background: rgb(37,119,220);
    display: flex;
    align-items: center;
    color: white;
    border-radius: 5px 5px 0 0;
}

.audit-order-win-title-content {
    width: 90%;
    margin-left: 12px;
    letter-spacing: 1px;
}

.mobile-audit-order-win-title-content {
    width: 90%;
    margin-left: 40px;
    letter-spacing: 1px;
}

.audit-order-win-body {
    width: 100%;
    height: 86%;
    background: rgb(255,255,255);
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0 0 5px 5px;
}

.order-history-layout {
    width: 98%;
    height: 98%;
}

.order-history-search {
    height: 7%;
    width: 100%;
    background: #72a1c9;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-record-data-export {
    height: 60%;
    min-height: 24px;
    width: 160px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-record-data-export-button {
    width: 70%;
    background: #2e78d0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    letter-spacing: 1px;
}

.export-date-outline {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 30px;
    color: black;
}

.export-button {
    border: 0;
    color: white;
    background: #2e78d0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    cursor: pointer;
}

.order-history-table {
    height: 84%;
    width: 100%;
    background: #c0dcea;
}

.page-partition-layout {
    height: 9%;
    width: 100%;
    background: #72a1c9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.partition-layout-content {
    width: 500px;
    height: 70%;
    background: #72a1c9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
}

.switch-page {
    width: 70%;
    background: #ececec;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 3px;
}

.switch-page-button {
    border: 0;
    width: 16%;
    background: #2e78d0;
    color: #e0e0e0;
    border-radius: 2px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.current-page {

}

.batch-process-win {
    height: 90%;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-batch-process-win {
    height: 600px;
    background: #eeeeee;
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-page {
    width: 28%;
    height: 100%;
    background: #577793;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.to-input-page {
    border: 0;
    width: 70%;
    background: #2e78d0;
    color: #e0e0e0;
    border-radius: 2px;
    box-shadow: 0 0 2px rgba(47, 47, 47, 0.6);
    cursor: pointer;
}

.search-bar {
    width: 300px;
    background: transparent;
    display: flex;
}

.user-search-bottom{
    border: 0;
    margin-left: 14px;
    background: #2e78d0;
    color: #e0e0e0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    cursor: pointer;
}

.mobile-user-search-bottom{
    border: 0;
    margin-left: 24px;
    width: 150px;
    letter-spacing: 1px;
    background: #2e78d0;
    color: #e0e0e0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    cursor: pointer;
}

.no-data-win {
    width: 100%;
    height: calc(100% - 10px);
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* modal */
.modal-win {
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    top: calc(50% - 200px);
    left: calc(50% - 325px);
    width: 650px;
    height: 400px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    white-space: pre-wrap;
}

.modal-win-title {
    width: 100%;
    height: 10%;
    background: rgb(37,119,220);
    display: flex;
    align-items: center;
    color: white;
    border-radius: 5px 5px 0 0;
}

.modal-win-title-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    width: 90%;
    height: 100%;
}

.modal-win-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    font-size: 28px;
    color: #ffffff;
}

.modal-win-textarea-container {
    width: 90%;
    height: 78%;
    border: 1px solid gray;
}

.modal-win-textarea {
    padding: 2%;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 26px;
}

.modal-win-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 10%;
}

.modal-win-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 80%;
    border: 1px solid #3180e0;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 4px;
    color: #3180e0;
    cursor: pointer;
}
