@import '~antd/dist/antd.css';

/* 滚动条设置 */
::-webkit-scrollbar { /* 滚动条 */
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb { /* 滚动条上的滚动滑块. */
    background: linear-gradient(transparent, rgba(104, 149, 203, 0.6)); /* 背景颜色 */
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(transparent, #32bf65);
}

.page-size {
    min-height: 640px;
    min-width: 1250px;
    /*background: grey;*/
    display: flex;
    align-items:center;/*垂直居中*/
    justify-content: center;/*水平居中*/
    padding-top: calc((100vh - 640px) / 2);
}

.mobile-login-page {
    width: 100vw;
    height: 100%;
    position: absolute;
    background: white;
}

.login-win {
    background: white;
    height: 400px;
    width: 600px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(102, 102, 102);
}

.mobile-login-win {
    background: white;
    height: 1000px;
    width: 100%;
    font-size: 40px;
}

.login-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18%;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 5px;
    color: white;
    background: #3180e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.mobile-login-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18%;
    font-weight: bold;
    letter-spacing: 5px;
    color: white;
    font-size: 50px;
    background: #3180e0;
}

.login-content {
    height: 82%;
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.mobile-login-content {
    height: 1100px;
    background: #ffffff;
}

.login-input-content {
    height: 76%;
}

.login-input-content-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 80%;
}

.login-button-content {
    height: 24%;
    /*background: grey;*/
    text-align: center;
}

.mobile-login-button-content {
    height: 150px;
    /*background: grey;*/
    text-align: center;
}

.mobile-login-input-line {
    font-size: 40px;
    width: 100%;
    height: 100px;
}

.mobile-login-input-line:focus {
    border: 0;
    outline: none;
    background: #cee5ff;
}

.mobile-login-button {
    font-size: 40px;
    line-height: 0;
    width: 260px;
    height: 100px;
    border: 0;
    color: white;
    background: #2e78d0;
    border-radius: 5px;
    cursor: pointer;
}

.login-button {
    font-size: 16px;
    line-height: 0;
    width: 100px;
    height: 40px;
    border: 0;
    color: white;
    background: #2e78d0;
    border-radius: 5px;
    cursor: pointer;
}

.login-windows-info-input-line {
    width: 70%;
}

.valid-win {
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(102, 102, 102);
    position: absolute;
    left: calc(50% - 250px);
    top: 30vh;
    height: 240px;
    width: 500px;
    background: white;
    z-index: 100;
}

.mobile-valid-wim {
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 100;
}

.mobile-valid-win-title {
    color: white;
    font-size: 44px;
    letter-spacing: 4px;
    font-weight: bold;
    background: #3180e0;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-phone-input {
    font-size: 30px;
    width: 44%;
    height: 100px;
}

.mobile-phone-input:focus {
    outline: none;
    border: 0;
    background: #abcfff;
}

.valid-win-title {
    color: white;
    font-size: 16px;
    letter-spacing: 4px;
    font-weight: bold;
    background: #3180e0;
    height: 22%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.valid-win-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
}

.mobile-valid-win-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px;
    background: #ffffff;
}

.valid-win-button {
    height: 28%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-valid-win-button {
    height: 28%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-valid-win-button-inside {
    background: #ffffff;
    height: 300px;
    color: white;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-evenly;
}

.valid-win-button-inside {
    height: 50%;
    color: white;
    width: 64%;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.win-bottom-button {
    cursor: pointer;
    line-height: 100%;
    width: 20%;
    border: 0;
    border-radius: 5px;
}

.mobile-win-bottom-button {
    cursor: pointer;
    font-size: 34px;
    letter-spacing: 2px;
    height: 90px;
    width: 180px;
    border: 0;
    border-radius: 5px;
}

.send-button {
    cursor: pointer;
    border-radius: 5px;
    width: 18%;
    border: 0;
    color: white;
}

.mobile-send-button {
    cursor: pointer;
    border-radius: 5px;
    width: 24%;
    min-width: 180px;
    border: 0;
    font-size: 30px;
    color: white;
}

.menu-layout {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70%;
    background: #e0e0e0;
    width: 94%;
    margin: auto;
}

.menu-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
    background: #ffffff;
}

.menu-button {
    color: #214e76;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 66%;
    font-weight: bold;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(114, 114, 114, 0.6);
}

.menu-button:hover {
    background: #2e77c9;
    border: 0;
    color: white;
}

.sub-menu-item:hover {
    background: #abcfff;
}

.item-msg-win {
    margin: auto;
    min-width: 1250px;
    min-height: 570px;
    width: 100%;
    position: absolute;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-order-reject {
    position: absolute;
    height: calc(100% - 150px);
    width: 100%;
    background: #ffffff;
    margin-top: 150px;
}

.mobile-order-reject-content {
    height: 1000px;
    background: #ffffff;
    display: flex;
    width: 90%;
    margin: auto;
    font-size: 32px;
    flex-direction: column;
    justify-content: space-evenly;
}

.mobile-reject-reason {
    font-size: 30px;
    height: 380px;
    background: #e7e7e7;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.mobile-reject-reason-edit-input {
    outline: none;
    width: 70%;
    height: 80px;
    font-size: 30px;
    border: 0;
}

.mobile-reject-reason-edit-input:focus {
    background: #abcfff;
}

.mobile-reject-reason-bottom {
    height: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mobile-reject-reason-bottom-button {
    height: 80px;
    width: 200px;
    border: 0;
    border-radius: 30px;
    color: white;
}

.mobile-reject-reason-op {
    width: 60%;
    border: 0;
    height: 70px;
    border-radius: 20px;
    background: white;
}

.mobile-item-msg-win {
    width: 100%;
    position: absolute;
    height: 100%;
    min-height: 1400px;
    z-index: 999;
}

.userPojo-layout {
    width: 84%;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 6px;
}

.userPojo-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userPojo-button {
    border: 0;
    background: #2e78d0;
    color: white;
    width: 80px;
    font-size: 14px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.user-award-list-win {
    width: 330px;
    height: 480px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.user-award-content {
    height: 90%;
    background: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.accept-line-data {
    margin-left: 6px;
    min-height: 90px;
    width: 280px;
    margin-bottom: 20px;
    background: #d6f1f6;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.accept-line-data-item {
    width: 92%;
    display: flex;
    flex-direction: column;
}

.error-msg-content {
    z-index: 99;
    background: #ffffff;
    min-height: 470px;
    min-width: 380px;
    max-width: 580px;
    max-height: 680px;
    height: 70%;
    width: 30%;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.mobile-error-msg-content {
    background: #ffffff;
    height: 100%;
    width: 100%;
}

.error-msg-content-layout {
    border-radius: 5px;
    background: #ffffff;
    width: 96%;
    height: 96%;
    overflow-y: scroll;
}

.error-single-line {
    background: #ffffff;
    width: 90%;
    min-height: 110px;
    margin: 2% auto 0 auto;
}

.error-msg-words-win {
    border-radius: 10px;
    min-height: 60px;
    font-size: 12px;
}

.mobile-error-msg-words-win {
    border-radius: 10px;
    min-height: 200px;
    font-size: 30px;
}

.processing-win {
    display: flex;
    color: #4c6982;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    width: 180px;
    height: 80px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

.core-pwd-win {
    height: 200px;
    width: 300px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
}

.mobile-core-pwd-win {
    height: 200px;
    width: 300px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
}

.core-pwd-win-title {
    width: 100%;
    height: 16%;
    background: #2e78d0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: right;
}

.core-user-pwd-win-title {
    width: 100%;
    height: 16%;
    background: #2e78d0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.core-pwd-win-content {
    width: 100%;
    height: 60%;
    background: #ffffff;
}

.core-pwd-win-content-boundary {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: space-around;
}

.core-pwd-win-button {
    width: 100%;
    height: 24%;
    background: #ffffff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.core-win-commit {
    border: 0;
    background: #2e78d0;
    color: white;
    width: 20%;
    height: 56%;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.core-valid-commit {
    border: 0;
    background: #8e8e8e;
    color: white;
    width: 18%;
    height: 44%;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.core-pwd-win-input {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.core-pwd-win-valid-win {
    width: 300px;
    height: 100px;
    background:  #2e78d0;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
}

.core-pwd-win-valid-win-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70%;
    width: 100%;
}

.mobile-menu {
    width: 100vw;
    height: 100%;
    position: absolute;
    background: white;
}

.mobile-header {
    height: 150px;
    background: #2e78d0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 34px;
    color: white;
    font-weight: bold;
}

.mobile-menu-content {
    height: 1100px;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.mobile-menu-item {
    width: 64%;
    height: 140px;
    background: #225ca1;
    color: white;
    border-radius: 10px;
    font-size: 40px;
    letter-spacing: 2px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
